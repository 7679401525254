/* General form styling */
.booking-form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-form h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form Fields Styling */
  .form-floating {
    margin-bottom: 15px;
  }
  
  .form-floating input,
  .form-floating select {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s ease;
  }
  
  .form-floating input:focus,
  .form-floating select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Floating Labels */
  .form-floating label {
    position: absolute;
    top: -10px;
    left: 10px;
    font-size: 14px;
    color: #777;
    background-color: #fff;
    padding: 0 4px;
  }
  
  .form-floating input:focus + label,
  .form-floating select:focus + label {
    color: #007bff;
  }
  
  /* Button Styles */
  .btn {
    display: inline-block;
    padding: 12px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }
  
  .btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  /* Layout Styles */
  .grid {
    display: grid;
    gap: 20px;
  }
  
  .grid-cols-1 {
    grid-template-columns: 1fr;
  }
  
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .col-span-full {
    grid-column: span 2;
  }
  
  /* Additional Layout Adjustments */
  @media (min-width: 768px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Form Type Selection */
  .form-floating select {
    padding-left: 20px;
  }
  
  /* Section Heading */
  .booking-form .form-floating.mb-3 {
    margin-bottom: 20px;
  }
  
  /* Select Input Styling */
  .form-floating select {
    padding-left: 20px;
  }
  