.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link.active {
    color : #E25217;
}

.navbar-dark .navbar-nav .nav-link {
    margin-right: 30px;
    padding: 25px 0;
    color: #E25217;
    font-size: 15px;
    text-transform: uppercase;
    outline: none;
}

.carousel-caption {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}
.bg-image {
    background-image: url('../public/assets/img/carousel-1.jpg');
}

html {
  scroll-behavior: smooth;
}

/* Prevent anchor tags from triggering scroll */
a[href^="#"] {
  scroll-behavior: auto;
}

/* For buttons and links that shouldn't trigger scroll */
.no-scroll {
  scroll-behavior: auto;
}